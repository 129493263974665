import { template as template_df40b48f39ad499b99cd2da31db07703 } from "@ember/template-compiler";
import { i18n } from "discourse-i18n";
import SortableColumn from "./sortable-column";
const PostersCell = template_df40b48f39ad499b99cd2da31db07703(`
  <SortableColumn
    @order="posters"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="posters"
    @screenreaderOnly={{true}}
    aria-label={{i18n "category.sort_options.posters"}}
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default PostersCell;
