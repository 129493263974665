import { template as template_336193c6c949448db20fcff4184ace88 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_336193c6c949448db20fcff4184ace88(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
