import { template as template_9f66fc20daf140e9b7302ab746b8e47a } from "@ember/template-compiler";
const FKLabel = template_9f66fc20daf140e9b7302ab746b8e47a(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
