import { template as template_233733e8347143dc91b7a82006187e05 } from "@ember/template-compiler";
const WelcomeHeader = template_233733e8347143dc91b7a82006187e05(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
